import {
  ACCOUNT_ROUTES,
  AUTOMATION_ROUTES,
  DASHBOARD,
  DONOR_ROUTES,
  GROUP_ADMIN_ROUTES,
  INFO_ROUTES,
  JOURNEY_ROUTES,
  MEDIA_ROUTES,
  MY_LOCKER,
  REPORTS_ROUTES,
  SHARE_ROUTES,
} from "../routes/constants.js";
import {
  Flag as FlagIcon,
  Folder as FolderIcon,
  Layout as LayoutIcon,
  MessageSquare as MessageSquareIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Film as FilmIcon,
  Globe as GlobeIcon,
  MessageCircle as MessageCircleIcon,
  Send as SendIcon,
  FileText as ReportIcon,
  Aperture as ApertureIcon,
  PlusCircle as PlusCircleIcon,
  Briefcase as BriefcaseIcon,
} from "react-feather";

import { PUBLIC_ROLES } from "../roleConstants";
import async from "../components/Async";
import MediaRequestReports from "../pages/public/Media/MediaRequestReports.js";
import MediaRequestTemplates from "../pages/public/Media/MediaRequestTemplates";

const Unsubscribed = async(() => import("../pages/shared/Unsubscribed"));
const InviteUser = async(() => import("../pages/public/InviteUser"));
const EditUser = async(() => import("../pages/public/EditUser"));
const Locker = async(() => import("../pages/public/Locker"));
const Campaigns = async(() => import("../pages/public/Share/Campaigns"));
const Profile = async(() => import("../pages/public/Profile/Index"));
const Users = async(() => import("../pages/public/Users"));
const ApiKeys = async(() => import("../pages/public/ApiKeys"));
const CreateApiKey = async(() => import("../pages/public/NewApiKey"));
const EditApiKey = async(() => import("../pages/public/EditApiKey"));

// group admin
const GroupAdminSentReport = async(() =>
  import("../pages/public/GroupAdministrators/SentReport")
);

const GroupAdminSentReportDetails = async(() =>
  import("../pages/public/GroupAdministrators/SentReportDetails")
);

//integration section
const Integrations = async(() => import("../pages/public/Integration/All"));
const IntegrationNeonOneCRM = async(() =>
  import("../pages/public/Integration/NeonOneCRM")
);
const IntegrationVirtuous = async(() =>
  import("../pages/public/Integration/Virtuous")
);
const IntegrationMindBody = async(() =>
  import("../pages/public/Integration/MindBody")
);

const PublicCharityEdit = async(() => import("../pages/public/CharityEdit"));
const PublicCtaButtons = async(() =>
  import("../pages/public/Share/CtaButtons")
);
const PublicShareSenders = async(() => import("../pages/public/Share/Senders"));
const PublicShareCreateShareSender = async(() =>
  import("../pages/public/Share/CreateShareSender")
);
const PublicShareEditShareSender = async(() =>
  import("../pages/public/Share/EditShareSender")
);

const PublicShareTemplates = async(() =>
  import("../pages/public/Share/Templates")
);
const PublicShareCreateShareTemplate = async(() =>
  import("../pages/public/Share/CreateShareTemplate")
);
const PublicShareEditShareTemplate = async(() =>
  import("../pages/public/Share/EditShareTemplate")
);
const PublicShareCreateStandardTemplate = async(() =>
  import("../pages/public/Share/CreateStandardTemplate")
);
const PublicShareEditStandardTemplate = async(() =>
  import("../pages/public/Share/EditStandardTemplate")
);
const CreateMediaRequestTemplate = async(() =>
  import("../pages/public/Media/CreateMediaRequestTemplate")
);
const EditMediaRequestTemplate = async(() =>
  import("../pages/public/Media/EditMediaRequestTemplate")
);
const PublicCampaignDetails = async(() =>
  import("../pages/public/Share/CampaignDetails")
);

const PublicMediaRequestDetails = async(() =>
  import("../pages/public/Media/MediaRequestReportsDetail")
);

const PublicMediaEdit = async(() => import("../pages/public/Media/Edit"));
const MediaRequest = async(() => import("../pages/public/Media/Request"));
const PublicMediaCreate = async(() => import("../pages/public/Media/Create"));
const PublicUploadLogo = async(() =>
  import("../pages/public/Media/UploadLogo")
);
const PublicShareCreate = async(() => import("../pages/public/Share/Create"));
const PublicSharePreview = async(() => import("../pages/public/Share/Preview"));
const PublicShareDetails = async(() => import("../pages/public/Share/Details"));
const Drafts = async(() => import("../pages/public/Share/Create/drafts"));
const Scheduled = async(() => import("../pages/public/Share/Scheduled"));
const ScheduledDetails = async(() =>
  import("../pages/public/Share/Scheduled/details")
);

const QuickShare = async(() =>
  import("../pages/public/Share/Create/QuickShare")
);

const PublicDonors = async(() => import("../pages/public/Donors/Donors"));
const PublicUnsubscribedDonors = async(() =>
  import("../pages/public/Reports/Unsubscribed")
);
const PublicThreads = async(() => import("../pages/public/Donors/Threads"));
const PublicThreadsDetail = async(() =>
  import("../pages/public/Donors/Threads/Detail")
);
const PublicDonorsDetail = async(() => import("../pages/public/Donors/Detail"));
const PublicDonorsUpdate = async(() => import("../pages/public/Donors/Update"));
const PublicDonorsCreate = async(() => import("../pages/public/Donors/Create"));
const PublicDonationsImport = async(() =>
  import("../pages/public/Donations/Import")
);

const PublicDonorMBOBulkTagging = async(() =>
  import("../pages/public/Donors/BulkTagging/MindBodyOnline")
);

// Public donor request stuff
const PublicDonorRequests = async(() =>
  import("../pages/public/PublicDonorRequests/All")
);
const PublicDonorRequestsCreate = async(() =>
  import("../pages/public/PublicDonorRequests/Create")
);
const PublicDonorRequestsEdit = async(() =>
  import("../pages/public/PublicDonorRequests/Edit")
);

const PublicDonorRequestsDetails = async(() =>
  import("../pages/public/PublicDonorRequests/Details")
);

const MediaRequestDetails = async(() =>
  import("../pages/public/Media/RequestDetails")
);
const MediaRequestUploadComplete = async(() =>
  import("../pages/public/Media/RequestUploadComplete")
);
const MediaRequestPreview = async(() =>
  import("../pages/public/Media/RequestPreview")
);
// automation stuff
const AutomationIndex = async(() =>
  import("../pages/public/Automations/Index")
);
const EditAutomation = async(() => import("../pages/public/Automations/Edit"));

// journey stuff
const JourneyIndex = async(() => import("../pages/public/Journeys/Index"));
const JourneyNew = async(() => import("../pages/public/Journeys/Edit"));
const JourneyEdit = async(() => import("../pages/public/Journeys/Edit"));

//organization stuff
const PublicOrganizations = async(() =>
  import("../pages/public/Organizations/All")
);
const PublicOrganizationsCreate = async(() =>
  import("../pages/public/Organizations/Create")
);
const PublicOrganizationsEdit = async(() =>
  import("../pages/public/Organizations/Edit")
);
const PublicOrganizationsUserCreate = async(() =>
  import("../pages/public/Organizations/Users/Create")
);

const PublicOrganizationsUserEdit = async(() =>
  import("../pages/public/Organizations/Users/Edit")
);

const Thumbnails = async(() =>
  import("../pages/public/Media/Thumbnails/Index")
);
const EditThumbnail = async(() =>
  import("../pages/public/Media/Thumbnails/Edit")
);

// Public media request stuff
const PublicMediaRequests = async(() =>
  import("../pages/public/PublicMediaRequests/All")
);
const PublicMediaRequestsCreate = async(() =>
  import("../pages/public/PublicMediaRequests/Create")
);
const PublicMediaRequestsEdit = async(() =>
  import("../pages/public/PublicMediaRequests/Edit")
);

const PublicMediaRequestsDetails = async(() =>
  import("../pages/public/PublicMediaRequests/Details")
);

const Default = async(() => import("../pages/layouts/Dashboard"));
const Responses = async(() => import("../pages/public/Share/Responses"));

const ChangePassword = async(() => import("../pages/shared/ChangePassword"));

// Pages Displayed In Sidebar
export const sidebarPublicRoutes = [
  //DASHBOARD
  {
    path: DASHBOARD,
    name: "Dashboard",
    icon: LayoutIcon,
    component: Default,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: null,
  },

  //SHARE
  {
    path: SHARE_ROUTES.CREATEBLANK,
    name: "Share",
    icon: SendIcon,
    component: PublicShareCreate,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: null,
  },

  //QUICK TEXT
  {
    path: SHARE_ROUTES.QUICK_TEXT,
    name: "Quick Text",
    icon: PlusCircleIcon,
    component: QuickShare,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: null,
  },

  //Conversations
  {
    path: DONOR_ROUTES.THREADS,
    name: "Conversations",
    component: PublicThreads,
    icon: MessageCircleIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    smsOnly: true,
    badgeColor: "warning",
    badgeData: "unreadMessageCount",
  },

  //LOCKER
  {
    path: MY_LOCKER,
    name: "My Locker",
    icon: FolderIcon,
    component: Locker,
    roles: [PUBLIC_ROLES.CONTENT_CREATOR],
    children: null,
    badgeColor: "warning",
    badgeData: "pendingApprovalCount",
  },

  {
    path: "/locker",
    name: "Content",
    icon: FilmIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: MY_LOCKER,
        name: "My Locker",
        icon: FolderIcon,
        component: Locker,
        children: null,
        badgeColor: "warning",
        badgeData: "pendingApprovalCount",
      },
      {
        path: MEDIA_ROUTES.REQUEST,
        name: "Request Media",
        component: MediaRequest,
      },

      {
        path: ACCOUNT_ROUTES.PUBLIC_MEDIA_REQUESTS,
        name: "Content Upload Pages",
        component: PublicMediaRequests,
      },
    ],
  },

  //CAMPAIGNS
  {
    path: "/campaigns",
    name: "Campaigns",
    icon: BriefcaseIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: SHARE_ROUTES.DRAFTS,
        name: "Drafts",
        component: Drafts,
      },
      {
        path: SHARE_ROUTES.SCHEDULED,
        name: "Scheduled",
        component: Scheduled,
      },
      {
        path: ACCOUNT_ROUTES.SHARE_TEMPLATES,
        name: "Share Templates",
        component: PublicShareTemplates,
      },

      {
        path: ACCOUNT_ROUTES.REQUEST_TEMPLATES,
        name: "Request Templates",
        component: MediaRequestTemplates,
      },
    ],
  },

  //Automations
  {
    path: "/automations",
    name: "Automations",
    icon: ApertureIcon,
    component: AutomationIndex,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: null,
  },

  // Journeys
  {
    path: "/journeys",
    name: "Journeys",
    icon: FlagIcon,
    component: JourneyIndex,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: null,
  },

  //AUDIENCE
  {
    path: "/supporters",
    name: "Audience",
    icon: UsersIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: DONOR_ROUTES.DONORS,
        name: "Audience Members",
        component: PublicDonors,
      },
      {
        path: DONOR_ROUTES.PUBLIC_DONOR_REQUESTS,
        name: "Sign up Pages",
        component: PublicDonorRequests,
      },
    ],
  },

  {
    path: "/reports",
    name: "Reports",
    icon: ReportIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.CONTENT_REVIEWER,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: REPORTS_ROUTES.SHARE_METRICS,
        name: "Sent Report",
        component: Campaigns,
        roles: [
          PUBLIC_ROLES.ADMINISTRATOR,
          PUBLIC_ROLES.CONTENT_REVIEWER,
          PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
        ],
      },
      {
        path: REPORTS_ROUTES.MEDIA_REQUEST_REPORTS,
        name: "Request Report",
        component: MediaRequestReports,
        roles: [
          PUBLIC_ROLES.ADMINISTRATOR,
          PUBLIC_ROLES.CONTENT_REVIEWER,
          PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
        ],
      },
      {
        path: REPORTS_ROUTES.UNSUBSCRIBED,
        name: "Unsubscribed",
        component: PublicUnsubscribedDonors,
        roles: [
          PUBLIC_ROLES.ADMINISTRATOR,
          PUBLIC_ROLES.CONTENT_REVIEWER,
          PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
        ],
      },
    ],
  },

  {
    path: "/group-administrators",
    name: "Report Administrator",
    icon: ReportIcon,
    roles: [PUBLIC_ROLES.REPORT_ADMINISTRATOR],
    children: [
      {
        path: GROUP_ADMIN_ROUTES.SENT_REPORT,
        name: "Sent Report",
        component: GroupAdminSentReport,
        roles: [PUBLIC_ROLES.REPORT_ADMINISTRATOR],
      },
    ],
  },

  //MY ORGANIZATION
  {
    path: "/myorganization",
    name: "My Organization",
    icon: GlobeIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: ACCOUNT_ROUTES.MY_CHARITY,
        name: "Details/Defaults",
        component: PublicCharityEdit,
      },
      {
        path: MEDIA_ROUTES.THUMBNAILS,
        name: "Thumbnails",
        component: Thumbnails,
      },
      {
        path: ACCOUNT_ROUTES.CTA_BUTTONS,
        name: "Call To Action Buttons",
        component: PublicCtaButtons,
      },
      {
        path: SHARE_ROUTES.RESPONSES,
        name: "Responses",
        icon: MessageSquareIcon,
        component: Responses,
        nonSMSOnly: true,
      },
    ],
  },

  {
    path: "/account",
    name: "My Account",
    icon: SettingsIcon,
    roles: [
      PUBLIC_ROLES.ADMINISTRATOR,
      PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
    ],
    children: [
      {
        path: ACCOUNT_ROUTES.PROFILE,
        name: "Profile",
        component: Profile,
        roles: [PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR],
      },
      {
        path: ACCOUNT_ROUTES.USERS,
        name: "Users",
        component: Users,
      },
      {
        path: ACCOUNT_ROUTES.SHARE_SENDERS,
        name: "Email Sender Accounts",
        component: PublicShareSenders,
      },
      {
        path: ACCOUNT_ROUTES.ORGANIZATIONS,
        name: "Child Organizations",
        component: PublicOrganizations,
      },
      {
        path: ACCOUNT_ROUTES.API_KEYS,
        name: "API Keys",
        component: ApiKeys,
      },
      {
        path: ACCOUNT_ROUTES.INTEGRATION_ALL,
        name: "Integration",
        component: Integrations,
      },
    ],
  },
];

// Pages Not Displayed In Sidebar

// Pages That Use "Dashboard" Template (Main Template)
export const nonSidebarPublicRoutes = {
  roles: Object.values(PUBLIC_ROLES),
  children: [
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword,
    },
    {
      path: ACCOUNT_ROUTES.INVITE_USER,
      name: "Invite New User",
      component: InviteUser,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_USER,
      name: "Edit User",
      component: EditUser,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_API_KEY,
      name: "Create API Key",
      component: CreateApiKey,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_API_KEY,
      name: "Edit API Key",
      component: EditApiKey,
    },
    {
      path: MEDIA_ROUTES.EDIT,
      name: "Edit Media",
      component: PublicMediaEdit,
    },
    {
      path: SHARE_ROUTES.CREATE,
      name: "Share Media",
      component: PublicShareCreate,
    },
    {
      path: MEDIA_ROUTES.CREATE,
      name: "Create Media",
      component: PublicMediaCreate,
    },
    {
      path: MEDIA_ROUTES.UPLOAD_LOGO,
      name: "Upload Logo",
      component: PublicUploadLogo,
    },

    {
      path: DONOR_ROUTES.IMPORT_DONATIONS,
      name: "Import Donations",
      component: PublicDonationsImport,
    },
    {
      path: DONOR_ROUTES.DONOR_DETAIL,
      name: "Audience Detail",
      component: PublicDonorsDetail,
    },
    {
      path: DONOR_ROUTES.THREAD_DETAIL,
      name: "Thread",
      component: PublicThreadsDetail,
    },
    {
      path: DONOR_ROUTES.CREATE,
      name: "Audience",
      icon: UsersIcon,
      component: PublicDonorsCreate,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },
    {
      path: DONOR_ROUTES.CREATE_PUBLIC_DONOR_REQUEST,
      name: "Create Audience Member Sign up Page",
      component: PublicDonorRequestsCreate,
    },
    {
      path: DONOR_ROUTES.EDIT_PUBLIC_DONOR_REQUEST,
      name: "Edit Audience Member Sign up Page",
      component: PublicDonorRequestsEdit,
    },
    {
      path: DONOR_ROUTES.UPDATE,
      name: "Audience Detail",
      component: PublicDonorsUpdate,
    },

    {
      path: DONOR_ROUTES.BULK_TAGGING_MBO,
      name: "Bulk Tagging",
      component: PublicDonorMBOBulkTagging,
    },

    {
      path: ACCOUNT_ROUTES.CREATE_SHARE_SENDER,
      name: "New Media Share Sender",
      component: PublicShareCreateShareSender,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_SHARE_SENDER,
      name: "Edit Media Share Sender",
      component: PublicShareEditShareSender,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_SHARE_TEMPLATE,
      name: "New Share Template",
      component: PublicShareCreateShareTemplate,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_SHARE_TEMPLATE,
      name: "Edit Share Template",
      component: PublicShareEditShareTemplate,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_STANDARD_TEMPLATE,
      name: "New Standard Template",
      component: PublicShareCreateStandardTemplate,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_STANDARD_TEMPLATE,
      name: "Edit Standard Template",
      component: PublicShareEditStandardTemplate,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_REQUEST_TEMPLATE,
      name: "New Request Template",
      component: CreateMediaRequestTemplate,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_REQUEST_TEMPLATE,
      name: "Edit Request Template",
      component: EditMediaRequestTemplate,
    },
    {
      path: REPORTS_ROUTES.SHARE_METRICS_DETAILS,
      name: "Campaign Details",
      component: PublicCampaignDetails,
    },
    {
      path: REPORTS_ROUTES.MEDIA_REQUEST_REPORTS_DETAILS,
      name: "Media Request Details",
      component: PublicMediaRequestDetails,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_PUBLIC_MEDIA_REQUEST,
      name: "Create Content Upload Page",
      component: PublicMediaRequestsCreate,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_PUBLIC_MEDIA_REQUEST,
      name: "Edit Content Upload Page",
      component: PublicMediaRequestsEdit,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_ORGANIZATION,
      name: "Edit Child Organization",
      component: PublicOrganizationsEdit,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_ORGANIZATION,
      name: "Create Child Organization",
      component: PublicOrganizationsCreate,
    },
    {
      path: ACCOUNT_ROUTES.EDIT_ORGANIZATION_USER,
      name: "Edit Child Organization",
      component: PublicOrganizationsUserEdit,
    },
    {
      path: ACCOUNT_ROUTES.CREATE_ORGANIZATION_USER,
      name: "Create Child Organization",
      component: PublicOrganizationsUserCreate,
    },
    {
      path: MEDIA_ROUTES.EDIT_THUMBNAIL,
      name: "Edit Thumbnail",
      component: EditThumbnail,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },
    {
      path: AUTOMATION_ROUTES.EDIT,
      name: "Edit Automation",
      component: EditAutomation,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.CONTENT_REVIEWER,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },
    {
      path: AUTOMATION_ROUTES.UPDATE,
      name: "Update Automation",
      component: EditAutomation,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.CONTENT_REVIEWER,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },
    {
      path: JOURNEY_ROUTES.EDIT,
      name: "Edit Journey",
      component: JourneyEdit,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.CONTENT_REVIEWER,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },
    {
      path: JOURNEY_ROUTES.NEW,
      name: "New Journey",
      component: JourneyEdit,
      roles: [
        PUBLIC_ROLES.ADMINISTRATOR,
        PUBLIC_ROLES.CONTENT_REVIEWER,
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR,
      ],
    },

    //integrations
    {
      path: ACCOUNT_ROUTES.INTEGRATION_NEON_ONE_CRM,
      name: "Neon One",
      component: IntegrationNeonOneCRM,
    },
    {
      path: ACCOUNT_ROUTES.INTEGRATION_VIRTUOUS,
      name: "Virtuous",
      component: IntegrationVirtuous,
    },
    {
      path: ACCOUNT_ROUTES.INTEGRATION_MIND_BODY,
      name: "MindBody Online",
      component: IntegrationMindBody,
    },
    {
      path: SHARE_ROUTES.SCHEDULED_DETAILS,
      name: "Scheduled Campaign Details",
      component: ScheduledDetails,
    },

    // group admin
    {
      path: GROUP_ADMIN_ROUTES.SENT_REPORT_DETAILS,
      name: "Sent Report Details",
      component: GroupAdminSentReportDetails,
    },
  ],
};

// Pages That Use Info Template
export const infoRoutes = {
  path: "/info",
  name: "Info",
  icon: UserIcon,
  children: [
    {
      path: DONOR_ROUTES.PUBLIC_DONOR_REQUEST,
      name: "Audience Member Sign up Page",
      component: PublicDonorRequestsDetails,
    },
    {
      path: MEDIA_ROUTES.PUBLIC_MEDIA_REQUEST,
      name: "Content Upload Page",
      component: PublicMediaRequestsDetails,
    },
    {
      path: MEDIA_ROUTES.REQUEST_DETAILS,
      name: "Media Request Details",
      component: MediaRequestDetails,
    },
    {
      path: MEDIA_ROUTES.REQUEST_UPLOAD_COMPLETE,
      name: "Media Request Upload Complete",
      component: MediaRequestUploadComplete,
    },
    {
      path: MEDIA_ROUTES.REQUEST_PREVIEW,
      name: "Media Request Details",
      component: MediaRequestPreview,
    },
    {
      path: INFO_ROUTES.UNSUBSCRIBED,
      name: "Unsubscribed",
      component: Unsubscribed,
    },
  ],
};

export const shareRoutes = {
  children: [
    {
      path: SHARE_ROUTES.PREVIEW,
      name: "Share Preview",
      roles: Object.values(PUBLIC_ROLES),
      component: PublicSharePreview,
    },
    {
      path: SHARE_ROUTES.DETAILS,
      name: "Share Details",
      component: PublicShareDetails,
    },
  ],
};
